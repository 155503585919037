import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
//import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Aboutus extends React.Component {
  render() {
    return (
      <>
       <div className="bg-default-section">
        <DemoNavbar />
        </div>
        <section className="section">
          <Container>
            <h1 className="display-1 mb-0">About Us</h1>
            <Row className="row-grid">
              <Col md="9">
                <p>
                  Krislin Consultants LLP is Pune based consultants dealing with Property Renting, Property Management and Legal Services for the property. The innovative services we offer will give immense pleasure and relief blended with the professional systematic approach for flat owners and tenants. All property care processes are well captured, documented, automated in state-of-the-art blended with highly scalable technology, which can be accessed anywhere, anytime.Flat owners who are away from their property for mutual benefit can have excellent benefits. Flat owners can stay anywhere in the world comfortably without any worry about his/her property and they can view their property online periodically and receive rent and get updates about the property. Tenants can search pleasurable and ideal home maintaining their privacy and contact us having property on rent.Our vision is to provide excellent property services and support for property owners and tenants in Pune. We do that with data, design, technology, and above all the passion of our people while delivering the services.
                        </p>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  className="img-fluid shadow shadow-lg--hover"
                  src={require("assets/img/theme/home.png")}
                  style={{ height: "300px", width: "300px" }}
                />
              </Col>
            </Row>

            <Row className="row-grid">
              <Col md="12">
                <p>
                  The economy of Pune is quite sprawling and acknowledged all around the country for its gigantic base of business sector including finance, manufacturing, IT Company, among others which drags job seekers from different states. Furthermore, another great attraction center in the city is educational institutes, the launch of many renowned colleges have opened the door for students. Everyone and everywhere is searching for the home or have their property vacant. We, Krislin Consultants LLP are here to provide Property Management Services, Property Rental Services and Legal Services for Property Related matters.

                  Krislin Consultants LLP provide you with complete set of Property related services such as Property Renting Service, Property Management Service, and Legal Service for Property related issues blended with effective technology to reduce the wastage of time, energy and cost.
                        </p>
              </Col>
            </Row>

          </Container>
        </section>
        <CardsFooter />

      </>
    );
  }
}

export default Aboutus;