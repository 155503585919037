import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import {
    Navbar,
    NavbarBrand,
    Button,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Container,
    Table,
    Row,
    Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
        original: require("assets/img/ill/prop/tata1.jpg"),
        thumbnail: require("assets/img/ill/prop/tata1.jpg"),
    },
    {
        original: require("assets/img/ill/prop/tata2.jpg"),
        thumbnail: require("assets/img/ill/prop/tata2.jpg"),
    },
    {
        original: require("assets/img/ill/prop/tata3.jpg"),
        thumbnail: require("assets/img/ill/prop/tata3.jpg"),
    },
    {
        original: require("assets/img/ill/prop/tata4.jpg"),
        thumbnail: require("assets/img/ill/prop/tata4.jpg"),
    },
    {
        original: require("assets/img/ill/prop/tata5.jpg"),
        thumbnail: require("assets/img/ill/prop/tata5.jpg"),
    },
];

class PropertyDetails extends React.Component {
    state = {
        tabs: 1
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    render() {
        return (
            <>
                <div className="bg-default-section">
        <DemoNavbar />
        </div>
                <section className="section property-details">
                    <Container fluid>
                        <div className="row-grid justify-content-center row">
                            <h1 className="display-2 mb-0">Tata la Montana</h1>
                        </div>
                        <Row className="row-grid">
                            <Col lg="8">
                                <ImageGallery items={images} />
                                <Row className="justify-content-center">
                                    <Col>
                                        <h4 className="mb-0"></h4>
                                        <div className="nav-wrapper">
                                            <Nav
                                                className="nav-fill flex-column flex-md-row"
                                                id="tabs-icons-text"
                                                pills
                                                role="tablist"
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        aria-selected={this.state.tabs === 1}
                                                        className={classnames("mb-sm-3 mb-md-0", {
                                                            active: this.state.tabs === 1
                                                        })}
                                                        onClick={e => this.toggleNavs(e, "tabs", 1)}
                                                        href="#pablo"
                                                        role="tab"
                                                    >
                                                        <i className="ni ni-cloud-upload-96 mr-2" />
                                                        Overview
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-selected={this.state.tabs === 2}
                                                        className={classnames("mb-sm-3 mb-md-0", {
                                                            active: this.state.tabs === 2
                                                        })}
                                                        onClick={e => this.toggleNavs(e, "tabs", 2)}
                                                        href="#pablo"
                                                        role="tab"
                                                    >
                                                        <i className="ni ni-bell-55 mr-2" />
                                                        Description
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem>
                                                    <NavLink
                                                        aria-selected={this.state.tabs === 3}
                                                        className={classnames("mb-sm-3 mb-md-0", {
                                                            active: this.state.tabs === 3
                                                        })}
                                                        onClick={e => this.toggleNavs(e, "tabs", 3)}
                                                        href="#pablo"
                                                        role="tab"
                                                    >
                                                        <i className="ni ni-calendar-grid-58 mr-2" />
                                                        Locality
                                                    </NavLink>
                                                </NavItem> */}
                                            </Nav>
                                        </div>
                                        <Card className="shadow">
                                            <CardBody>
                                                <TabContent activeTab={"tabs" + this.state.tabs}>
                                                    <TabPane tabId="tabs1">
                                                        <p className="Lead">
                                                            Semi-furnished Apartment to rent for all type of tenant. This 640 sqft 1BHK Apartment. Apartment comes with a convenient space for parking of car and bike. This 1 BHK apartment comes with an affordable price tag of just 10,000 rupees.
                                                        </p>
                                                        <h4>Property Details</h4>
                                                        <Table>
                                                            <tr>
                                                                <th>1. Floor </th>
                                                                <td>5/6</td>
                                                            </tr>
                                                            <tr>
                                                                <th>2. Furnishing Status </th>
                                                                <td>Semi Furnished</td>
                                                            </tr>
                                                            <tr>
                                                                <th>3. Water Supply </th>
                                                                <td>Corporation and Borewell</td>
                                                            </tr>
                                                            {/* <th>2. Apartment</th>
                                                            <td>3. Remote Garage</td> */}

                                                        </Table>
                                                    </TabPane>
                                                    <TabPane tabId="tabs2">
                                                        <p className="Lead">
                                                            The house is Fully Furnished with Sofa set, Dining table, Tv in Living room, 2 bedroom with wardrobes, 3 bed in all the bedroom, Kitchen cabinets, Fridge, Geysers, AC in master bedroom are available.
                                                        </p>
                                                    </TabPane>
                                                    {/* <TabPane tabId="tabs3">
                                                        <p className="description">
                                                            Raw denim you probably haven't heard of them jean shorts
                                                            Austin. Nesciunt tofu stumptown aliqua, retro synth master
                                                            cleanse. Mustache cliche tempor, williamsburg carles vegan
                                                            helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                                                            synth.
                                                        </p>
                                                    </TabPane> */}
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="4">
                                <h5>Activity on Property</h5>
                                <Table><tr>
                                    <td>
                                        <div class="block1">
                                            <i class="ni ni-satisfied ni-2x"> </i><p className="Lead"> Views <br />123</p>
                                        </div>
                                    </td>
                                    <td><div class="block1">
                                        <i class="ni ni-favourite-28 ni-2x"> </i><p className="Lead"> Shortlists <br />2</p>
                                    </div></td>
                                    <td><div class="block1">
                                        <i class="ni ni-active-40 ni-2x"> </i><p className="Lead"> Contacted <br />6</p>
                                    </div></td></tr>
                                </Table>

                                <Table className="table-bordered table-striped table">
                                    <tbody>
                                        <tr><td><i class="fa fa-bed"> </i> 3 Bedroom</td>
                                            <td><i class="fa fa-building"> </i> Apartment</td></tr>
                                        <tr><td><i class="fa fa-users"> </i> Family</td>
                                            <td><i class="fa fa-key"> </i> Immidiately</td></tr>
                                        <tr><td><i class="fa fa-car"> </i> Bike and Car</td>
                                            <td><i class="fa fa-hospital-o"> </i> > 10 years</td></tr>
                                        <tr><td><i class="fa fa-leaf"> </i> 1 Balcony</td>
                                            <td><i class="fa fa-calendar-minus-o"> </i> 14 days ago</td></tr>
                                        <tr><td><i class="ni ni-compass-04"> </i> Facing - North</td>
                                            <td><i class="ni ni-app"> </i> Semi Furnished</td></tr>
                                    </tbody>
                                </Table>
                                <Button block color="default"> Enquiry with us</Button>

                                <Table><th> <h4>Locality of Property</h4></th>
                                    <tr>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.547704295196!2d73.64429444982997!3d18.729057067591842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ade1f0f16c91%3A0x4651e63cde11521d!2sTata%20La%20Montana%20Talegaon!5e0!3m2!1sen!2sin!4v1586154888017!5m2!1sen!2sin" width="400" height="300" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                    </tr>
                                </Table>
                            </Col>
                        </Row>

                    </Container>
                    <Row>
                        <Container>
                            <td>
                                <div className="block2">
                                    <i class="fa fa-bath fa-3x"> </i><h4> 24/7<br></br><p>Water Supply</p></h4>
                                </div></td>
                            <td><div className="block2">
                                <i class="fa fa-car fa-3x"> </i><p className="Lead"> Covered parking</p>
                            </div></td>
                            <td> <div className="block2">
                                <i class="fa fa-bed fa-3x"> </i><p className="Lead"> 3 Bhk</p>
                            </div></td>
                            <td> <div className="block2">
                                <i class="ni ni-compass-04 fa-3x"> </i><p className="Lead"> North Facing</p>
                            </div></td>

                        </Container>
                    </Row>
                </section>
                <CardsFooter />

            </>
        );
    }
}

export default PropertyDetails;