import Index from "user/views/Index.jsx";
import Profile from "user/views/examples/Profile.jsx";
import Postproperty from "user/views/examples/Postproperty.jsx";
import Enquiry from "user/views/examples/Enquiry.jsx";
//import Login from "user/views/examples/Login.jsx";
import Postedproperty from "user/views/examples/Postedproperty.jsx";
import TenantDocuments from "user/views/examples/TenantDocuments.jsx";
import OwnerDocuments from "user/views/examples/OwnerDocuments.jsx";
import ChangePassword from "user/views/examples/ChangePassword.jsx";
import Logout from "user/views/examples/logout";

import Editproperty from "user/views/examples/Editproperty.jsx";
//import Icons from "user/views/examples/Icons.jsx";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/user"
  },{
 
    path: "/Postproperty",
    name: "Post property",
    icon: "fa fa-home text-primary",
    component: Postproperty,
    layout: "/user"
  },{
    path: "/Postedproperty",
    name: "Posted property",
    icon: "fa fa-table text-primary",
    component: Postedproperty,
    layout: "/user"
  
  },
  {
    path: "/Enquiry",
    name: "Enquiry Legal Service",
    icon: "ni ni-circle-08 text-pink",
    component: Enquiry,
    layout: "/user"
  },
  {
    path: "/TenantDocuments",
    name: "Teanant Documents",
    icon: "fa fa-file-upload text-primary",
    component: TenantDocuments,
    layout: "/user"
  },
  {
    path: "/OwnerDocuments",
    name: "Owner Documents",
    icon: "fa fa-file-upload text-primary",
    component: OwnerDocuments,
    layout: "/user"
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: "fa fa-user text-primary",
    component: Profile,
    layout: "/user"
  },

  {
    path: "/Editproperty",
    name: "Editproperty",
    icon: "ni ni-button-power",
    component: Editproperty,
    layout: "/user"
  },

  {
    path: "/change_password",
    name: "Change Password",
    icon: "fa fa-key text-primary",
    component: ChangePassword,
    layout: "/user"
  },
  
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-button-power",
    component: Logout,
    layout: "/user"
  },
  
  
  /*,
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  }*/
];
export default routes;
