import React from "react";
import axios from 'axios'; 

import {  Button,Card,CardHeader,CardBody,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Container,Row,Col,Label } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

const config = require('../../config.json');
const jwt = require("jsonwebtoken");
class Register extends React.Component {

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  constructor() {
      super();
      this.state = {
        fields: {},
        errors: {}
      }

     this.handleChange = this.handleChange.bind(this);
     this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }

  submituserRegistrationForm(e) 
  {
    e.preventDefault();
    if (this.validateForm()) 
    {
        let fields      = this.state.fields;
        //fields.mobileno = fields.mobileno

        this.setState({
            errors:{
                login_msg:"Please wait...",
                login_cls:"info",
                login_icon:"fa fa-exclamation-circle"
            }                
          });
          try
          {
            axios.post(config.api_url+'/enquiry/add',fields)
            .then(res=>{

                this.setState({
                  errors:res.data.error                
                });

                if(res.data.status)
                {
                  this.setState({
                      fields: {}
                  })
                  //window.location.href=config.site_url;
                }
            })
          }
          catch(e)
          {
            console.log(e)
          }
    }

  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["propertyName"]) {
      formIsValid = false;
      errors["propertyName"] = "*Please enter property name.";
    }

    if (!fields["customername"]) {
      formIsValid = false;
      errors["customername"] = "*Please enter your name.";
    }

    if (typeof fields["customername"] !== "undefined") {
      if (!fields["customername"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["customername"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    
    if (!fields["mobile"]) {
      formIsValid = false;
      errors["mobile"] = "*Please enter your mobile no.";
    }

    if (typeof fields["mobile"] !== "undefined") {
      if (!fields["mobile"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobile"] = "*Please enter valid mobile no.";
      }
    }

    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "*Please enter the subject.";
    }

    if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = "*Please enter the message.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  
  
  showMessage = (alert_msg,alert_class,fa_icon) => {
    var fa_icon_display = '';
    if(fa_icon !== undefined)
    {
        fa_icon_display = <i className={fa_icon} style={{marginRight: '5px'}} ></i>        
    }
    if(alert_msg === undefined) {
        return false;
    }else{
      return(
          <div className={"alert alert-" + alert_class}>
              {fa_icon_display} 
              {alert_msg}
          </div>
      )
    }
}



  render() {
    return (
      <>
       <div className="bg-default-section">
        <DemoNavbar />
        </div>
        <main ref="main">
          <section className="section section-shaped">
            <h1 className=" font-weight-bold text-center">
              We are waiting to assist you...
                </h1>
            <Row className="justify-content-center">
              <p className="lead">Simply call, email or use the form below to get in touch.</p>
            </Row>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg="12"><div class="mapouter"><div class="gmap_canvas"> <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Krislin%20Consultants%20LLP%20Office%20No.%20101%2C%20Kumar%20Sahwas%2C%20Baner%20%E2%80%93%20Pashan%20Link%20Road%2C%20Behind%20Ojas%20Society%2C%20Pune%2C%20Maharashtra%20%E2%80%93%20411021%2C%20India.&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div></Col>
                <Col lg="12">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <h3 className=" font-weight-bold text-center text-primary"> ENQUIRY NOW</h3>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                    {this.showMessage(this.state.errors.error_msg,this.state.errors.error_cls,this.state.errors.error_icon)}
                      <Form role="form">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-tag" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Property Name" name="propertyName" type="text"  value={this.state.fields.propertyName}  onChange={this.handleChange} />
                              </InputGroup>
                              <p style={{color:"red",fontSize:"12px"}}>{this.state.errors.propertyName}</p>
                            </FormGroup>
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-email-83" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input name="email" placeholder="Email" type="email" value={this.state.fields.email}  onChange={this.handleChange} />
                              </InputGroup>
                              <p style={{color:"red",fontSize:"12px"}}>{this.state.errors.email}</p>
                            </FormGroup>
                            <FormGroup>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fa fa-address-card" />
                                  </InputGroupText>
                                </InputGroupAddon>
                               
                                <Input placeholder="Subject" name="subject" type="text"  value={this.state.fields.subject}  onChange={this.handleChange} autoComplete="off" />
                              </InputGroup>
                              <p style={{color:"red",fontSize:"12px"}}>{this.state.errors.subject}</p>
                            </FormGroup>
                          </Col>
                          <Col lg="5">
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-hat-3" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Customer Name" name="customername" type="text"  value={this.state.fields.customername}  onChange={this.handleChange} />
                              </InputGroup>
                              <p style={{color:"red",fontSize:"12px"}}>{this.state.errors.customername}</p>
                            </FormGroup>
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-mobile-button" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input name="mobile" placeholder="Mobile" type="mobile" value={this.state.fields.mobile}  onChange={this.handleChange} />
                              </InputGroup>
                              <p style={{color:"red",fontSize:"12px"}}>{this.state.errors.mobile}</p>
                            </FormGroup>
                            <FormGroup>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fa fa-location-arrow" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input name="message" placeholder="Message" type="message" value={this.state.fields.message}  onChange={this.handleChange}  autoComplete="off" />
                              </InputGroup>
                              <p style={{color:"red",fontSize:"12px"}}>{this.state.errors.message}</p>
                            </FormGroup>
                          </Col>
                          <Col lg="3"><br />
                            <i class="icon ni ni-square-pin text-red"></i>Pune, India.<br />
                            <i class="icon ni ni-email-83 text-red"></i>krislin@gmail.com<br />
                            <i class="icon ni ni-mobile-button text-red"></i>+91-8080021644
                          </Col>
                        </Row>

                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                                name = "checkTerm"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                                <span>
                                  I agree with the{" "}
                                  <a
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-center">
                          < Button onClick={this.submituserRegistrationForm} 
                              className="mt-4"
                              color="primary"
                              type="button">
                            Submit
                            </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
        {/* <CardsFooter /> */}
      </>
    );
  }
}

export default Register;