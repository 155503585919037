
import React from "react";
import classnames from "classnames";

// reactstrap components
import { Button, Container, Row, Col,  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown } from "reactstrap";

class Hero extends React.Component {
  state = {};
  render() {
    return (
      <>
        <div className="position-relative">
        <img
                      alt="..."
                      className="img-center img-fluid banner"
                      src={require("assets/img/ill/banner1.jpg")}
                    />
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
         
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
           
            <Container className="shape-container d-flex align-items-center py-lg">
              
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="12">
                    <div className="header_container">
                    <h1 className=" text-white">
                    Rent your Property and stay hassle free!
                    </h1>
      <h3  className=" text-white">Sell a home and manage the moving process all in one place</h3>
      <form>
          <Container className="mb-5">

            <Row>
              <Col lg="3" sm="6">
               
                <FormGroup
                  className={classnames({
                    focused: this.state.searchFocused
                  })}
                >
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-zoom-split-in" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Location"
                      type="text"
                      onFocus={e => this.setState({ searchFocused: true })}
                      onBlur={e => this.setState({ searchFocused: false })}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="3" sm="6">

              <select class="browser-default custom-select" style = {{ maxWidth: "200px"}} > 
              <option selected>Property Type</option> 
              <option value="1">Apartments/Flats</option> 
              <option value="2">Independent Houses/Villas</option> 
              <option value="3">Row House</option> 
              </select>
              </Col>
              <Col lg="3" sm="6">
                
              <select class="browser-default custom-select" style = {{ maxWidth: "200px"}} > 
              <option selected>Flat Type</option> 
              <option value="1">1 BHK</option> 
              <option value="2">2 BHK</option> 
              <option value="3">3 BHK</option> 
              <option value="4">4+ BHK</option>
              <option value="5">1 RK</option> 
              <option value="6">2+ RK</option>
              <option value="7">1 HALL</option> 
              </select>
              
              </Col>
              <Col lg="3" sm="6">
                <FormGroup>
                <Button
                            className="btn btn-default btn-block"
                            color=""
                            href= "/listing"
                            type="button"
                          >
                            Search
                          </Button>
                </FormGroup>
              </Col>
             
            </Row>
          </Container>
         
         		
      </form>
    </div>
                    
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
           
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
