import React from "react";
import axios from 'axios'; 
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const config = require('../../config.json');

// core components


class Login extends React.Component {
  state = {};

  constructor(props)
  {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      otp_request_id:"",
      reset_pass_user_id:"",
      show_login:true,
      show_loginotp:false,
      show_forgotpassword:false,
      show_forgotpassword1:false,
      show_forgotpass_otp:false,
      show_resetPassword:false
    }

    this.handleChange = this.handleChange.bind(this);
    this.submitLogin  = this.submitLogin.bind(this);
    this.submitOtp    = this.submitOtp.bind(this);
    this.submitForgotEmail = this.submitForgotEmail.bind(this);
    this.showForgotPassBox = this.showForgotPassBox.bind(this);
    this.submitForgotMobile = this.submitForgotMobile.bind(this);
    this.submitVerifyForgotOtp = this.submitVerifyForgotOtp.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
    
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;  
    this.setState({
      fields
    });
  }

  submitResetPassword(e){
      e.preventDefault();  

      let forgotData = {
        email:this.state.fields.forgot_email,
        mobile:this.state.fields.forgot_mobile,
        otp:this.state.fields.f_otp,
        password:this.state.fields.r_password,
        reset_pass_user_id:this.state.reset_pass_user_id
    }
      axios.post(config.api_url+'/auth/reset_password', forgotData)
        .then(res=>{
            this.setState({ errors:res.data.error });
            if(res.data.status)
            {
                this.setState({
                    fields: {},
                    otp_request_id:"",
                    reset_pass_user_id:"",
                });
              
                this.setState({
                    show_forgotpassword:false,
                    show_forgotpassword1:false,
                    show_loginotp:false,
                    show_login:true,
                    show_forgotpass_otp:false,
                    show_resetPassword:false
                });
            }
        })
  }

  submitVerifyForgotOtp(e){
      e.preventDefault();  

      let forgotData = {
          email:this.state.fields.forgot_email,
          mobile:this.state.fields.forgot_mobile,
          otp:this.state.fields.f_otp
      }

      axios.post(config.api_url+'/auth/verify_forgot_otp', forgotData)
        .then(res=>{

          this.setState({ errors:res.data.error });
          if(res.data.status)
          {
              this.setState({
                  show_forgotpassword:false,
                  show_forgotpassword1:false,
                  show_loginotp:false,
                  show_login:false,
                  show_forgotpass_otp:false,
                  show_resetPassword:true
              });

              this.setState({
                reset_pass_user_id:res.data.user_id
              })
          }

        })


  }

  submitForgotMobile(e){
    e.preventDefault();  
    
      let forgotData = {
        email:this.state.fields.forgot_email,
        mobile:this.state.fields.forgot_mobile
      }

      axios.post(config.api_url+'/auth/send_forgot_otp', forgotData)
        .then(res=>{
            this.setState({ errors:res.data.error });
            if(res.data.status)
            {
                this.setState({
                  show_forgotpassword:false,
                  show_forgotpassword1:false,
                  show_loginotp:false,
                  show_login:false,
                  show_forgotpass_otp:true,
                  show_resetPassword:false,
                });
            }
        })
  }

showForgotPassBox(e) {
  e.preventDefault();
  this.setState({
    show_forgotpassword:true,
    show_forgotpassword1:false,
    show_loginotp:false,
    show_login:false,
    show_forgotpass_otp:false,
    show_resetPassword:false
  });
}

  submitForgotEmail(e) {
      e.preventDefault();
      let forgotData = this.state.fields.forgot_email;
      forgotData = {
        email:forgotData
      }
      axios.post(config.api_url+'/auth/verify_email', forgotData)
        .then(res=>{
            this.setState({ errors:res.data.error });

            if(res.data.status){
              this.setState({
                  show_forgotpassword:false,
                  show_forgotpassword1:true,
                  show_loginotp:false,
                  show_login:false,
                  show_forgotpass_otp:false,
                  show_resetPassword:false
                })
            }
        })
      //console.log(forgotData);

  }

  submitOtp(e) {
    e.preventDefault();
    // validation required
    let otp = this.state.fields;
    otp.otp_request_id = this.state.otp_request_id;

    axios.post(config.api_url+'/auth/verify_login_otp', otp)
    .then(res=>{ 
          this.setState({errors:res.data.error})
          if(res.data.status)
          {
              localStorage.setItem('accessToken',res.data.accessToken);
              localStorage.setItem('refreshToken',res.data.refreshToken);  
              window.location.href = config.site_url+'/user/index';
          }else{
            this.setState({
              fields: {},
              otp_request_id:"",
              show_forgotpassword:false,
              show_forgotpassword1:false,
              show_loginotp:true, 
              show_login:false,
              show_forgotpass_otp:false,
              show_resetPassword:false
            });
          }
    })

  }


  submitLogin(e) {
      e.preventDefault();
      // validation required
      let login = this.state.fields;
      axios.post(config.api_url+'/auth', login)
      .then(res=>{ 
            this.setState({errors:res.data.error})
            if(res.data.status)
            {
                this.setState({
                    show_loginotp:true,
                    show_forgotpassword:false,
                    show_forgotpassword1:false,
                    show_login:false,
                    show_forgotpass_otp:false,
                    show_resetPassword:false,
                    otp_request_id:res.data.otp_request_id
                });
            }else{
              this.setState({
                fields: {},
                show_forgotpassword:false,
                show_forgotpassword1:false,
                show_loginotp:false,
                show_login:true,
                show_forgotpass_otp:false,
                show_resetPassword:false,
                otp_request_id:""
              });
            }
      })

  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  showMessage = (alert_msg,alert_class,fa_icon) => {
    var fa_icon_display = '';
    if(fa_icon !== undefined )
    {
        fa_icon_display = <i className={fa_icon} style={{marginRight: '5px'}} ></i>        
    }
    if(alert_msg === undefined) {
        return false;
    }else{
      return(
          <div className={"alert alert-" + alert_class}>
              {fa_icon_display} 
              {alert_msg}
          </div>
      )
    }
}

  render() {
    return (
      <>
        
        <main ref="main">
          <section className="">
            
            <Container className="modal-container">
              <Row className="justify-content-center">
                <Col lg="12">
                  <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                       
                          <div className="text-muted text-center font-weight-bold">
                            <h2>Sign in </h2>
                          </div>
                        </CardHeader>
                        {this.showMessage(this.state.errors.login_msg,this.state.errors.login_cls,this.state.errors.login_icon)}

                    <CardBody className="px-lg-5 py-lg-5" className={this.state.show_login === false ? 'hidden' : ''}  >
                     
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText> <i className="ni ni-email-83" /> </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" type="email" name="emailid" value={this.state.fields.emailid}  onChange={this.handleChange} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText> <i className="ni ni-lock-circle-open" /></InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Password" type="password" autoComplete="off" name="password" value={this.state.fields.password}  onChange={this.handleChange} />
                          </InputGroup>
                        </FormGroup>
                        
                        <div className="text-center">
                          <Button  onClick={this.submitLogin} className="my-4" color="primary" type="button" > Sign in </Button>
                        </div>
                        <Col xs="8">
                      <a className="text-dark" href="#pablo" onClick={this.showForgotPassBox} >
                        <small>Forgot password?</small>
                      </a>
                    </Col>
                        <a  href="/register-page" className="text-muted">Don't have an account? 
                          <Button  className="btn btn-default btn-sm" color="default" type="button" > Sign Up </Button>
                        </a>
                      </Form>
                     
                    </CardBody>

                    <CardBody className="px-lg-5 py-lg-5" className={this.state.show_loginotp === true ? '' : 'hidden'}  >

                          <Form role="form" >
                              <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText> <i className="ni ni-email-83" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="OTP" type="text" name="otp" value={this.state.fields.otp}  onChange={this.handleChange} />
                                </InputGroup>
                              </FormGroup>

                              <div className="text-center">
                                <Button  onClick={this.submitOtp} className="my-4" color="primary" type="button" > Verify </Button>
                              </div>
                              
                          </Form>
                    </CardBody>

                    <CardBody className="px-lg-5 py-lg-5" className={this.state.show_forgotpassword === false  ? 'hidden' : ''}  >
                        <Form role="form">
                          <FormGroup className="mb-3">
                                <label>Forgot Password</label>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText> <i className="ni ni-email-83" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="Email" type="text" name="forgot_email" value={this.state.fields.forgot_email}  onChange={this.handleChange} />
                                </InputGroup>
                              
                              </FormGroup>

                              <div className="text-center">
                                <Button  onClick={this.submitForgotEmail} className="my-4" color="primary" type="button" > Confirm Email </Button>
                              </div>
                        </Form>
                    </CardBody>


                    <CardBody className="px-lg-5 py-lg-5" className={this.state.show_forgotpassword1 === false  ? 'hidden' : ''}  >
                        <Form role="form">
                          <FormGroup className="mb-3">
                                <label>Forgot Password</label>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText> <i className="fa fa-mobile" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="Mobile" type="text" name="forgot_mobile" value={this.state.fields.forgot_mobile}  onChange={this.handleChange} />
                                </InputGroup>
                              
                              </FormGroup>

                              <div className="text-center">
                                <Button  onClick={this.submitForgotMobile} className="my-4" color="primary" type="button" > Send Otp </Button>
                              </div>
                        </Form>
                    </CardBody>


                    <CardBody className="px-lg-5 py-lg-5" className={this.state.show_forgotpass_otp === false  ? 'hidden' : ''}  >
                        <Form role="form">
                          <FormGroup className="mb-3">
                                <label>Verify Your OTP</label>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText> <i className="fa fa-mobile" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="OTP" type="text" name="f_otp" value={this.state.fields.f_otp}  onChange={this.handleChange} />
                                </InputGroup>
                              
                              </FormGroup>

                              <div className="text-center">
                                <Button  onClick={this.submitVerifyForgotOtp} className="my-4" color="primary" type="button" > Verify Otp </Button>
                              </div>
                        </Form>
                    </CardBody>


                    <CardBody className="px-lg-5 py-lg-5" className={this.state.show_resetPassword === false  ? 'hidden' : ''}  >
                        <Form role="form">
                          <FormGroup className="mb-3">
                                <label>Reset Password</label>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText> <i className="fa fa-key" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="New Password" type="password" name="r_password" value={this.state.fields.r_password}  onChange={this.handleChange} />
                                </InputGroup>
                              
                                </FormGroup>

                                <FormGroup className="mb-3">

                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText> <i className="fa fa-key" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="Confirm Password" type="password" name="r_cpassword" value={this.state.fields.r_cpassword}  onChange={this.handleChange} />
                                </InputGroup>
                              

                              </FormGroup>

                              <div className="text-center">
                                <Button  onClick={this.submitResetPassword} className="my-4" color="primary" type="button" > Reset Password </Button>
                              </div>
                        </Form>
                    </CardBody>



                  </Card>
                 


                </Col>
              </Row>
            </Container>
          </section>
        </main>
       
      </>
    );
  }
}

export default Login;
