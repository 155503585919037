import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
//import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
// reactstrap components
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";



class Tenant extends React.Component {
  render() {
    return (
      <>
        <div className="bg-default-section">
        <DemoNavbar />
        </div>
        <section className="section">
         
          <Container>
            <Row>
              <Col md="12">
                <h1 className="mb-0">The way we make it easy for you..!</h1>
                <h3>View the list of properties</h3>
              </Col>
            </Row>

            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <p>
                  We understand that your time is limited in this hustle-bustle life hence we always believe in ready to move homes! The homes we provide are pre-verified and are well maintained. You need not to worry about anything.View our ready to use homes with one click. Click on the property you find best suited
                  for you and send us the enquiry for the property you find the best and leave the rest hassle to us. You can even leave a comment about your Property Needs with Us
                        </p>
              </Col>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/View-the-list-of-properties.png")}
                  style={{ height: "250px", width: "250px" }}
                />
              </Col>

            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>

            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/One-Point-Contact.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h2>One - Point Contact</h2>
                <p>
                  We believe in keeping everything under one roof for tenants .You don't have to be in contact with multiple dealers or even the owner. You can contact us for any issues and at any point of time. Right from moving in to moving out, we provide you with a dedicated point of contact to answer all your queries in order to enhance your living experience and to help you settle comfortably in your new home.
                      </p>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>

            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <h2>Legal Service</h2>
                <p>
                  We do all the complicated legal work so that you won't have to waste your time moving around the courts.We are the one point of contact to negotiate the financial & other terms of the agreement. We handle the Registration of Agreement, society NOC, Police Verification other permissions paperwork.
                      </p>
              </Col>

              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/legal_services.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>


            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/Maintenance-request.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h3>Maintenance request</h3>
                <p>
                  In the era of busy life, we understand everyone barely have few minutes of peace and it should not be wasted with maintenance of the home, so we provide you with maintenance with a call or click away. Request us for any maintenance and repair work with a call or click since we have tie ups with various vendors providing best quality repair and price.
                      </p>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>

            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <h2>Online Payment</h2>
                <p>
                  It's fast, easy, and secure, so why wait? Pay your rent online!
                  Tenants can pay the rent through our online payment option.
                      </p>
              </Col>

              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/payrentonline.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>
            </Row>

          </Container>
        </section>
        <CardsFooter />
      </>
    );
  }
}

export default Tenant;