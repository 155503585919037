import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTestimonial, MDBAvatar,  MDBIcon } from "mdbreact";

const Menus = () => {
  return (
    <MDBContainer>
      <section className="team-section text-center my-5">
          <h2 className="h1-responsive font-weight-bold my-5">
          HAPPY CUSTOMERS
          </h2>
         

          <MDBRow className="text-center">
            <MDBCol md="6" className="mb-md-0 mb-5">
              
               
                 
                  
               
                <h4 className="font-weight-bold dark-grey-text mt-4">
                 99 Ideas LLp
                </h4>
                <h6 className="font-weight-bold blue-text my-3">
                India
                </h6>
                <p className="font-weight-normal dark-grey-text">
                  <MDBIcon className="fa fa-quote-left pr-2" />
                  Krislin Consultancy LLP is by far the easiest , most-intuitive and least buggy, I have worked with!
                </p>
                <div className="orange-text">
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                
                </div>
             
            </MDBCol>
            <MDBCol md="6" className="mb-md-0 mb-5">
              
              
            
               
                <h4 className="font-weight-bold dark-grey-text mt-4">
                Four Pillars Infotech India Pvt. Ltd
                </h4>
                <h6 className="font-weight-bold blue-text my-3">
                  India
                </h6>
                <p className="font-weight-normal dark-grey-text">
                  <MDBIcon className="fa fa-quote-left pr-2" />
                  Working with Krislin exceeded our expectations. They established a schedule executed with discipline and focus, and their process made us accountable for our own website. The flexibility of their software is a major reason why we selected Krislin. It’s simple to use will allow to grow
                   and add more value for our Service over time.
                </p>
                <div className="orange-text">
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                </div>
            
            </MDBCol>
            
          </MDBRow>
        </section>
    </MDBContainer>
  );
}

export default Menus;