
import React from "react";
import axios from 'axios';
import { UncontrolledCarousel, CardHeader, Form, FormGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label, Container, Button } from "reactstrap";

// plugin that creates slider
import Coverflow from "react-coverflow";
import { StyleRoot } from 'radium';
import classnames from "classnames";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// reactstrap components
import { Row, Col,  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane } from "reactstrap";
  const item1 = [
    {
        src: require("assets/img/ill/prop/tata1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/tata2.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];
const item2 = [
    {
        src: require("assets/img/ill/prop/ten1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/ten2.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/ten3.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];
const item3 = [
    {
        src: require("assets/img/ill/prop/lt1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/lt2.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/lt3.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];
const item4 = [
    {
        src: require("assets/img/ill/prop/br1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/br2.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/br3.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];

class CustomControls extends React.Component {

  constructor(props) {
    super(props)
    const config = require('../../config.json');
    this.api_url = config.api_url;
    this.subfolder = config.subfolder;   
    this.state = {
        errors:{},
        alert_class:'',
        fa_icon:"", 
        property :{}

      }

}
  
componentDidMount() {
  axios.get(this.api_url +'/property/all')
  .then(res => {
    console.log(res.data);
      this.setState({

        property: res.data.result,

      })

  }); 

}
  render() {
    
    var settings = {
      dots: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      adaptiveHeight: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
    
      <>
      <section className="section bg-secondary property">
          
          <h1 className=" font-weight-bold text-center">
          LATEST RENTAL PROPERTY

                </h1>
                <div>
       
        <Slider {...settings}>

      {Object.entries(this.state.property).map((content, i) => (
                                 <div>
                                  <Col lg="12">
                                  <img

                                    alt="..."
                                    class="img-new"
                                    src={require("assets/img/ill/new-gif.gif")}
                                    top
                                    />
                                        <Card>
                                            {/* <CardHeader>Tata la Montana 10000/-</CardHeader> */}
                                            <img
                                              class="property-img"
                                              alt="..."
                                              src={require("assets/img/ill/tennessee-img.webp")}
                                              top
                                            />
                                            <CardBody>
                                                <h5> {content[1].propertyName}<span style={{ color: "#0b1526", float: "right" }}><i class="fa fa-inr"></i>{content[1].price}/- </span> </h5>
                                               
                                                <div>
                                                    <button type="button" class="btn btn-default btn btn-sm"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-default btn-sm"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                            <ul class="room-features">
                                    <li>
                                        <i class="fa fa-arrows"></i>
                                        <p>{content[1].larea}</p>
                                    </li>
                                    <li>
                                        <i class="fa fa-bed"></i>
                                        <p>{content[1].bedroom}</p>
                                    </li>
                                    <li>
                                        <i class="fa fa-bath"></i>
                                        <p>{content[1].bathroom}</p>
                                    </li>
                       
                                </ul>
                                        </Card>
                                    </Col>
                                </div>

))}
                               
                              
        </Slider>
        <Container fluid>
                  
                    <div className="rounded shadow-lg overflow-hidden ">
                        {/* <Col lg="12"> */}

                        <Row>
                           
                            <Col lg="12">
                                <Row>
                                    <Col lg="4" className="dd">
                                        <Card>
                                            {/* <CardHeader>Tata la Montana 10000/-</CardHeader> */}
                                            <UncontrolledCarousel items={item1} />
                                            <CardBody>
                                                <h5>Tata la Montana <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>10000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Pune, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 620 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 1BHK</span>
                                                    </h6>
                                                </div>
                                                <br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4" className="dd">
                                        <Card>
                                            <UncontrolledCarousel items={item2} />
                                            <CardBody>
                                                <h5>Tennessee <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>15000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Pune, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 2000 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 3BHK</span>
                                                    </h6>
                                                </div><br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                               
                                    <Col lg="4" className="dd">
                                        <Card>
                                            <UncontrolledCarousel items={item3} />
                                            <CardBody>
                                                <h5>L&T Seawoods Limited <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>62000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Mumbai, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 1053 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 1BHK</span>
                                                    </h6>
                                                </div>
                                                <br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    {/* <Col lg="6" className="dd">
                                        <Card>
                                            <UncontrolledCarousel items={item4} />
                                            <CardBody>
                                                <h5>Blue Ridge <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>25000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Pune, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 550 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 1BHK</span>
                                                    </h6>
                                                </div>
                                                <br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>

                        {/* </Col> */}
                    </div>
                </Container>
      </div>
      
   
  </section>
      </>
    );
  }
}

export default CustomControls;
