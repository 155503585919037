import React from "react";
import axios from "axios"; 

// reactstrap components
import {  Button, Card, CardHeader, CardBody, FormGroup, Form,Input,InputGroupAddon,InputGroupText,InputGroup,Container, Row, Col } from "reactstrap";

// core components
import UserHeader from "user/components/Headers/Header";
const config = require('../../../config.json');
const jwt = require("jsonwebtoken");
class Postproperty extends React.Component {

  
    constructor(props)
    {
      super(props);
      let userdata1 = jwt.verify(localStorage.getItem("accessToken"), config.jwtSecret);
      this.state = {
          fields: {},
          user_id : userdata1.id,
          errors: {}
       }
      
       this.handleChange = this.handleChange.bind(this);
       this.submitPostPropertyForm = this.submitPostPropertyForm.bind(this);

    };

    handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }

    submitPostPropertyForm(e) 
    {
        e.preventDefault();
        if (this.validateForm()) 
        {

           let fields = this.state.fields;
           this.setState({
              errors:{
                  login_msg:"Please wait...",
                  login_cls:"info",
                  login_icon:"fa fa-exclamation-circle"
              }                
            });
          try{
            fields.user_id = this.state.user_id
            axios.post(config.api_url+'/property/add',fields,
            {headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`} })
            .then(res=>{
                this.setState({
                  error:res.data.error.error_msg,   
                });
                if(res.data.status)
                {
                  this.setState({
                      fields: {}
                  })
                    //window.location.href=config.site_url;
                }
            })
          }catch(e){
            console.log(e)
          }
          
        }
    }

   validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        this.setState({
          errors: errors
        });
        return formIsValid;
    }  



  showMessage = (alert_msg,alert_class,fa_icon) => {
      var fa_icon_display = '';
      if(fa_icon !== undefined)
      {
          fa_icon_display = <i className={fa_icon} style={{marginRight: '5px'}} ></i>        
      }
      if(alert_msg === undefined) {
          return false;
      }else{
        return(
            <div className={"alert alert-" + alert_class} style={{float: 'left',color: 'red',margin:'0 !important'}}>
                {fa_icon_display} 
                {alert_msg}
            </div>
        )
      }
  }


  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
           
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Post Property</h3>
                    </Col>
                   
                  </Row>
                </CardHeader>
                <CardBody>
                {this.showMessage(this.state.errors.submit_error,this.state.alert_class,'fa fa-check')}
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                    Property Details
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Property Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="propertyName"
                              placeholder="Property Name"
                              type="text"
                              value={this.state.fields.propertyName}
                              onChange={this.handleChange}
                            />
                            {this.showMessage(this.state.errors.propertyName,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                             Property Description
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="A few words about you ..."
                              rows="4"
                              type="textarea"
                              name="propertyDescription"
                              value={this.state.fields.propertyDescription}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                          {this.showMessage(this.state.errors.propertyDescription,this.state.alert_class)}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                             Property Status
                            </label>
                            <select   name="propertyStatus" value={this.state.fields.propertyStatus}
                              onChange={this.handleChange} class="browser-default custom-select" style = {{ maxWidth: "500px"}} > 
                              <option selected>Apartment</option> 
                              <option value="1">House</option> 
                            </select>
                            {this.showMessage(this.state.errors.propertyStatus,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                             Home Category
                            </label>
                            <select  name="category"
                              value={this.state.fields.category}
                              onChange={this.handleChange}   class="browser-default custom-select" style = {{ maxWidth: "500px"}} > 
                                <option selected>1 RK</option> 
                                <option value="1">1 BHK</option> 
                                <option value="2">2 BHK</option>  
                                <option value="3">3 BHK</option>  
                                <option value="4">4 BHK</option>  
                                </select>
                                {this.showMessage(this.state.errors.category,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                     Upload
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Upload your images
                            </label>
                            <input type="file" id="files" name="files[]" multiple=""/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                     Property Address
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Home Address"
                              type="text"
                              name="address"
                              value={this.state.fields.address}
                              onChange={this.handleChange}
                            />
                             {this.showMessage(this.state.errors.address,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="City"
                              type="text"
                              name="city"
                              value={this.state.fields.city}
                              onChange={this.handleChange}
                            />
                             {this.showMessage(this.state.errors.city,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Country"
                              type="text"
                              name="country"
                              value={this.state.fields.country}
                              onChange={this.handleChange}
                            />
                             {this.showMessage(this.state.errors.country,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Postal code"
                              type="number"
                              name="pincode"
                              value={this.state.fields.pincode}
                              onChange={this.handleChange}
                            />
                              {this.showMessage(this.state.errors.pincode,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">House Features</h6>
                    <div className="pl-lg-4">
                    <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Bedroom
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Bedroom"
                              type="text"
                              name="bedroom"
                              value={this.state.fields.bedroom}
                              onChange={this.handleChange}
                            />
                              {this.showMessage(this.state.errors.bedroom,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Bathroom
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Bathroom"
                              type="text"
                              name="bathroom"
                              value={this.state.fields.bathroom}
                              onChange={this.handleChange}
                            />
                               {this.showMessage(this.state.errors.bathroom,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                             Rooms
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Rooms"
                              type="number"
                              name="rooms"
                              value={this.state.fields.rooms}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Toilet
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Toilet"
                              type="text"
                              name="toilet"
                              value={this.state.fields.toilet}
                              onChange={this.handleChange}
                            />
                             {this.showMessage(this.state.errors.toilet,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Garages
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Garages"
                              type="text"
                              name="Garages"
                              value={this.state.fields.Garages}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                             Open Space
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder=" Open Space"
                              type="number"
                              name="spaces"
                              value={this.state.fields.spaces}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Year Built
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder=" Year Built"
                              type="text"
                              name="Year"
                              value={this.state.fields.Year}
                              onChange={this.handleChange}
                            />
                             {this.showMessage(this.state.errors.Year,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Land Area
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Land Area"
                              type="text"
                              name="larea"
                              value={this.state.fields.larea}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                             Land Unit
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder=" Land Unit"
                              type="number"
                              name="lunit"
                              value={this.state.fields.lunit}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Unit
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Unit"
                              type="text"
                              name="unit"
                              value={this.state.fields.unit}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Price
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Price"
                              type="text"
                              name="price"
                              value={this.state.fields.price}
                              onChange={this.handleChange}
                            />
                            {this.showMessage(this.state.errors.price,this.state.alert_class)}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button onClick={this.submitPostPropertyForm} className="btn-icon btn-3" color="primary" type="button">Submit</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Postproperty;
