import React from "react";
import axios from 'axios';

// reactstrap components
import {Badge,Card,CardHeader,CardFooter,DropdownMenu,DropdownItem,UncontrolledDropdown,DropdownToggle,Media, Pagination,
  PaginationItem, PaginationLink,Progress,Table,Container, Row, UncontrolledTooltip } from "reactstrap";
// core components
import Header from "user/components/Headers/Header.jsx";
const config = require('../../../config.json');
const jwt = require("jsonwebtoken");
var Loader = require('react-loader');


class Postedproperty extends React.Component {

  constructor(props)
  {
      super(props);

      let userdata1 = jwt.verify(localStorage.getItem("accessToken"), config.jwtSecret);
      this.state = {
          errors:{},
          alert_class:'',
          isLoading: false,
          fa_icon:"fa fa-exclamation-circle",
          userId : userdata1.id,
          propertyData:{}
        }
  }

  componentDidMount()
  {
    axios.get(config.api_url+'/property/all',    
    {headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`} }).then(res=>
     { 
       this.setState({
          propertyData:res.data.result,
      }) 
    });
  }


   

  render() {

    let i = 0;
    const property_data = [];
    Object.entries(this.state.propertyData).forEach(function (content) {
      {
          property_data.push(
            <tr>
                <td>{++i}</td>
                <td>{content[1].propertyName}</td>
                <td>{content[1].propertyStatus}</td>
                <td>{content[1].category} Min</td>
                <td>{content[1].city}</td>
                <td>{content[1].price}</td>
                <td>
                    <a target="_blank" href="#" className="btn btn-success btn-sm" >View</a>
                    <a target="_blank" href="#" className="btn btn-danger btn-sm" >Delete</a>
                </td>
            </tr>
          )
      }
     });

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Property Details</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.NO</th>
                      <th scope="col">PROPERT NAME</th>
                      <th scope="col">POPERTY STATUS</th>
                      <th scope="col">category</th>
                      <th scope="col">CITY</th>
                      <th scope="col">PRICE</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                      {property_data}
                  </tbody>
                </Table>
               
               
               <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
         
        </Container>
      </>
    );
  }
}

export default Postedproperty;
