import React from "react";

import {Button,Card,CardHeader,Form,CardBody,FormGroup,InputGroup,UncontrolledTooltip,Input ,NavItem,NavLink,Nav,Progress,Table,Container,Row,Col,InputGroupAddon,InputGroupText} from "reactstrap";
import UserHeader from "user/components/Headers/Header";
import axios from 'axios';
const config = require('../../../config.json');

class ChangePassword extends React.Component {

    constructor(props)
    {
        super(props);
        this.api_url = config.api_url;
    }

  

    render() {

        
      let resetForm;


          resetForm = <Form role="form" onSubmit={this.onSubmit}>


          <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="off"
                  required value={this.state.password} onChange={this.onChangepassword} 
                  onBlur={this.handleBlurPassword}
                />
              </InputGroup>
              {this.showMessage(this.state.errors.password,this.state.alert_class,'fa fa-lock')}
            </FormGroup>

            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Confirm Password"
                  type="password"
                  autoComplete="off"
                  required value={this.state.confirm_password} onChange={this.onChangeconfirm_password} 
                />
              </InputGroup>
              {this.showMessage(this.state.errors.confirm_password,this.state.alert_class,'fa fa-lock')}
              
            </FormGroup>
            
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="submit"
                disabled = {this.state.isLoading} 
              >
               Change Password
              </Button>
            </div>
            
      </Form> 
  
      return (
        <>
          <UserHeader />
          <main ref="main">
            <section className="section">
              <div className="shape shape-style-1 bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="pt-lg-md">
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Card className="bg-secondary shadow border-0">
                      <CardBody className="px-lg-5 py-lg-5">

                      
                      <div className="text-center text mb-4" >
                          Change Password
                      </div> 

                      {this.showMessage(this.state.errors.submit_error,this.state.alert_class,'fa fa-exclamation-circle')}
                      
                      { resetForm }
                        
                      </CardBody>
                    </Card>
                    
                  </Col>
                </Row>
              </Container>
            </section>
          </main>
      
        </>
      ) 
    }
}

export default ChangePassword;
