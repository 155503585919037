import React from "react";
import classnames from "classnames";
// reactstrap components
import {Button,Card,CardHeader,Form,CardBody,FormGroup,InputGroup, UncontrolledTooltip,Input ,NavItem,NavLink,Nav,Progress,Table,Container,Row,Col} from "reactstrap";

import UserHeader from "user/components/Headers/Header";
import axios from 'axios';
const jwt = require("jsonwebtoken");
const config = require('../../../config.json');

class Profile extends React.Component {

    constructor(props)
    {
        super(props);
        const config = require('../../../config.json');
        this.api_url = config.api_url;


        this.onChangefname        = this.onChangefname.bind(this);
        this.onChangelname        = this.onChangelname.bind(this);
        this.onChangeemailid      = this.onChangeemailid.bind(this);
        this.onChangemobileno     = this.onChangemobileno.bind(this);
        this.onChangeaddress      = this.onChangeaddress.bind(this);
        this.onChangecity         = this.onChangecity.bind(this);
        this.onChangecountry      = this.onChangecountry.bind(this);
        this.onChangezipCode      = this.onChangezipCode.bind(this);
        this.handleBlurZipcode    = this.handleBlurZipcode.bind(this);
        this.handleBlurMobile     = this.handleBlurMobile.bind(this);
        /*
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleBlurLName = this.handleBlurLName.bind(this);
        */
        let userdata1 = jwt.verify(localStorage.getItem("accessToken"), config.jwtSecret);
        this.onSubmit = this.onSubmit.bind(this);
    
        this.state = {
          userdata :{},
          fname: '',
          lname: '',
          emailid: '',
          mobileno: '',
          address: '',
          state:'',
          city:'',
          country:'',
          zipcode:'',
          errors:{},
          alert_class:'',
          userId : userdata1.id,
          isLoading: false
        }
    }

    onChangefname(e) {
      this.setState({
        fname: e.target.value
      })
    }
    onChangelname(e) {
      this.setState({
        lname: e.target.value
      })  
    }

    onChangeemailid(e) {
      this.setState({
        emailid: e.target.value
      })
    }

    onChangemobileno(e) {
      this.setState({
        mobileno: e.target.value
      })
      
    }

    onChangeaddress(e) {
      this.setState({
        address: e.target.value
      })
    }


    onChangezipCode = (e)=> 
    {
      this.setState({zipcode:e.target.value}, function() 
      {
        if(this.state.zipcode.length === 6)
        {
            var places = "";
            axios.get("https://api.postalpincode.in/pincode/"+ this.state.zipcode)
            .then(response=> {
              const result = (response.data[0]);
              places = (result.PostOffice)
              const city    = places[0][`District`];
              const country = places[0][`Country`];
              this.setState({
                  city: city,
                  country: country,
                  errors:{},
                  alert_class : ""
              })
          })
          .catch(error => {
            this.setState({
              errors:{zipcode:"No City Or State Found For Entered Zipcode. "},
              alert_class : "danger",
              city: "",
              zipcode: "",
              
            })
          })
        }
        if(this.state.zipcode.length >= 7)
        {
            this.setState({zipcode:""});
        }
    });
  }
   
  handleBlurZipcode(e)
  {
    let errors = {};
    let formIsValid = true;
     if (e.target.value.length != 6) {
        formIsValid = false;
        errors["zipcode"] = "* Zipcode not Valid";
        
        this.setState({
          errors: errors,
          alert_class : "warning",
          state: "",
          city: "",
          zipcode: "",
        });
        return formIsValid;
      }
      else{

        formIsValid = true;
        this.setState({
        //  errors: "",
        //  alert_class : ""
       
        });
        return formIsValid;
      }

  }

  onChangecity(e) {
    this.setState({
      city: e.target.value
    })
  }

  onChangestate(e) {
    this.setState({
      state: e.target.value
    })
  }

  onChangecountry(e) {
    this.setState({
      country: e.target.value
    })
  }
  numberOnly(e) {
    const re = /[0-9A-F:]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  charcterOnly(e) {
    const re = /[^0-9]/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  
  
  handleBlurMobile(e){
    let errors = {};
    let formIsValid = true;
    if (e.target.value !== "undefined") {
      if(e.target.value.length != 10)
      {
        formIsValid = false;
        errors["mobileno"] = "* Mobile number Must be 10 digit";
        this.setState({
          errors: errors,
          alert_class : "warning"
        });
        return formIsValid;
      }else{

        formIsValid = true;
        this.setState({
          errors: "",
          alert_class : ""
        });
        return formIsValid; 
      }

    }
  }

    componentDidMount()
    {
      axios.get(config.api_url+'/users/'+this.state.userId,{headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`} })
      .then(res => 
      {
          this.setState({
                userdata:res.data,
                fname : res.data.fname,
                lname : res.data.lname,
                emailid : res.data.emailid,
                mobileno : res.data.mobileno,
                address : res.data.address,
                zipcode : res.data.zipcode,
                city : res.data.city,
                state : res.data.state,
                password : res.data.password,
                is_email_verify:res.data.is_email_verify,
                is_email_verify:res.data.is_email_verify,
                id : res.data.id,
                errors:res.data.errors,
                alert_class: res.data.alert_class,
                errors : ""
              })
        });
  }

  showMessage = (alert_msg,alert_class,fa_icon) => {
    var fa_icon_display = '';
    if(fa_icon !== undefined)
    {
        fa_icon_display = <i className={fa_icon} style={{marginRight: '5px'}} ></i>        
    }
    if(alert_msg === undefined) {
        return false;
    }else{
      return(
          <div className={"alert alert-" + alert_class}>
              {fa_icon_display} 
              {alert_msg}
          </div>
      )
    }
}

onSubmit(e)
{
      e.preventDefault();
      const register  = 
      {
        fname: this.state.fname,
        lname: this.state.lname,
        emailid: this.state.emailid,
        mobileno: this.state.mobileno,
        address: this.state.address,
        zipcode:this.state.zipcode,
        city:this.state.city,
       // state:this.state.state,
        country:this.state.country,
        id : this.state.id,
       // password :this.state.password,
      }
     axios.patch(this.api_url+'/users/'+ this.state.id , register,{headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`} })
    .then(res => {
        
              this.setState({
                    errors:res.data.errors,  
                    alert_class:res.data.alert_class,    
                  });
                  if(res.data.status)
                  {
                    this.setState({
                        fields: {}
                    })
                      //window.location.href=config.site_url;
                     //window.location.reload('/users/profile');   
                  }
                 
                  
        });
}



  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm" >
                        Settings
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                {this.showMessage(this.state.errors.error_msg,this.state.alert_class,'fa fa-check')}
                  <Form onSubmit={this.onSubmit}>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">

                    <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name" >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              Value={this.state.userdata.fname}
                              onKeyPress={(e) => this.charcterOnly(e)}
                              onChange={this.onChangefname}
                            />
                              {this.showMessage(this.state.errors.fname,this.state.alert_class,'fa fa-mobile')}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              Value={this.state.userdata.lname}
                              onKeyPress={(e) => this.charcterOnly(e)}
                              onChange={this.onChangelname}
                            />
                              {this.showMessage(this.state.errors.lname,this.state.alert_class,'fa fa-mobile')}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email">
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="test@example.com"
                              type="email"
                              Value={this.state.userdata.emailid}
                              onChange={this.onChangeemailid}
                              required readOnly
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email">
                              Mobile Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Mobile Number"
                              type="emMobileail"
                              defaultValue={this.state.userdata.mobileno}
                              onKeyPress={(e) => this.numberOnly(e)}
                              onChange={this.onChangemobileno}
                              onBlur={this.handleBlurMobile}
                              maxlength = "10"
                              required 
                            />
                             { this.showMessage(this.state.errors.mobileno,this.state.alert_class,'fa fa-mobile')}
                          </FormGroup>
                        </Col>
                      </Row>
                    
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-address"
                              placeholder="Home Address"
                              type="text"
                              defaultValue={this.state.userdata.address}
                              onChange={this.onChangeaddress}

                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                      <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder="Postal code"
                              type="number"
                              autoComplete="off"
                              defaultValue={this.state.userdata.zipcode}
                              required value={this.state.zipcode} onChange={this.onChangezipCode}
                              onKeyPress={(e) => this.numberOnly(e)}
                              maxlength="6" 
                              onBlur={this.handleBlurZipcode}
                            />
                          </FormGroup>
                          {this.showMessage(this.state.errors.zipcode,this.state.alert_class,"fa fa-map-pin")}
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="New York"
                              id="input-city"
                              placeholder="City"
                              type="text"
                              defaultValue={this.state.city}
                              onChange={this.onChangecity}
                              required readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="United States"
                              id="input-country"
                              placeholder="Country"
                              type="text"
                              defaultValue={this.state.country}
                              onChange={this.onChangecountry}
                              required readOnly
                            />
                          </FormGroup>
                        </Col>
                       
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">Security</h6>
                    <div className="pl-lg-4">
                     <p>Change Password(<a href="changepassword">Click here</a>)</p>
                    </div>
                    <div className="text-center">
                        <Button
                        className="btn btn-primary"
                        color="primary"
                        type="submit">
                          update
                        </Button>
                      </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;