import React from "react";

class Logout extends React.Component {
  
    constructor(props)
    {
        super(props);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location="/";
    }

    render(){
        return("")
    }
}

export default Logout;
