/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardBody,
  Badge 
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
       <section id="footer">
		<div class="container">
			<div class="row text-center text-xs-center text-sm-left text-md-left">
				<div class="col-xs-12 col-sm-4 col-md-4">
        <h5> ABOUT US</h5>
                          <p className="description mt-3 text-white">
                          Krislin Consultants LLP is Pune based consultants dealing with Property Renting,
                           Property Management and Legal Services for the property.
                          </p>
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Read more
                          </Button>
				</div>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h5>Legal</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Terms and Conditions</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Privacy Policy</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Disclaimer</a></li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h5>CONTACT</h5>
          <p className="description mt-3 text-white">
          <i class="fa fa-map-marker"></i> Office No. 101, Kumar Sahwas, Baner – Pashan Link Road, Behind Ojas Society, 
                          Pune, Maharashtra – 411021, India.
                          </p>
					<ul class="list-unstyled quick-links">
						<li><a href="javascript:void();"><i class="fa fa-mobile"></i>91-8080021644</a></li>
					</ul>
				</div>
        
			</div>
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
					<ul class="list-unstyled list-inline social text-center">
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-facebook"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-twitter"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-instagram"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-google-plus"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();" target="_blank"><i class="fa fa-envelope"></i></a></li>
					</ul>
				</div>
				
			</div>	
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
					<p class="h6 text-white">&copy All right Reversed.<a class="text-green ml-2" href="https://www.sunlimetech.com" target="_blank"> krislin Consultants LLP</a></p>
				</div>
			
			</div>	
		</div>
	</section>
      </>
    );
  }
}

export default CardsFooter;
