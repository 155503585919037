import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

//import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import List from "../IndexSections/List.js";

class Listing extends React.Component {
  render() {
    return (
      <>
       <div className="bg-default-section">
        <DemoNavbar />
        </div>
        <List />
        <CardsFooter />

      </>
    );
  }
}

export default Listing;