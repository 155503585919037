/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import Login from "views/examples/Login.js";



// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Modal
} from "reactstrap";

const config      = require("../../config");
const jwt         = require('jsonwebtoken');


class DemoNavbar extends React.Component {
  state = {};

  constructor(props)
  {
      super(props);

      let is_logged_in = false;

      var accessToken  = localStorage.getItem("accessToken");
      jwt.verify(accessToken, config.jwtSecret, function(err, decodedToken) {
        if(err) { 
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          //window.location.href = config.site_url ;
        }
        else {
            if(decodedToken.id==undefined || decodedToken.id==null || decodedToken.id=="" )
            {
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("accessToken");
              window.location.href = config.site_url ;
            }
            
            is_logged_in = true;
      
      
        }
      });


      this.state = {
        is_logged_in:is_logged_in
      }

  }

  
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  componentWillMount(){
    require("../../assets/vendor/nucleo/css/nucleo.css");
    require("../../assets/vendor/font-awesome/css/font-awesome.min.css");
    require("../../assets/scss/argon-design-system-react.scss?v1.1.0");
  }
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
           
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/krislin-logo.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/krislin-logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem>
                    <NavLink to="/" tag={Link}>
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/about-page" tag={Link}>
                      About Us
                    </NavLink>
                  </NavItem>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Services</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/Management" tag={Link}>
                        Property Management
                      </DropdownItem>
                      <DropdownItem to="/Owner" tag={Link}>
                        Property Owner
                      </DropdownItem>
                      <DropdownItem to="/Tenant" tag={Link}>
                        Tenant
                      </DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Legal Services</span>
                    </DropdownToggle>
                    {/* <DropdownMenu>
                      <DropdownItem to="/landing-page" tag={Link}>
                        Landing
                      </DropdownItem>
                      <DropdownItem to="/profile-page" tag={Link}>
                        Profile
                      </DropdownItem>
                      <DropdownItem to="/login-page" tag={Link}>
                        Login
                      </DropdownItem>
                      <DropdownItem to="/register-page" tag={Link}>
                        Register
                      </DropdownItem>
                    </DropdownMenu> */}
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink to="/Contact" tag={Link}>
                      Contact Us
                    </NavLink>
                  </NavItem>

                </Nav>
                {/* <Col md="2">

    
                <a href="/user/index"
                    className={this.state.is_logged_in === true  ? 'btn btn-default btn-sm ' : 'btn btn-default btn-sm hidden'}  
                  >
                    My Account
            </a>

                  <Button
                    block
                    color="default"
                    type="button"
                    onClick={() => this.toggleModal("formModal")}

                    className={this.state.is_logged_in === false  ? 'btn btn-default btn-sm ' : 'btn btn-default btn-sm hidden'}  
                  >
                    Login
            </Button>
                  <Modal
                    
                    className={this.state.is_logged_in === false  ? 'modal-dialog-centered' : 'modal-dialog-centered hidden'}  
                    
                    size="sm"
                    isOpen={this.state.formModal}
                    toggle={() => this.toggleModal("formModal")}
                  >
                    <div className="modal-body p-0">
                    <button
                  aria-label="Close"
                  className="close close1"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("formModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
                   <Login/>
                   </div>
                  </Modal>
                </Col> */}
              </UncontrolledCollapse>
           
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;