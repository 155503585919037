import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "user/components/Headers/Header";

class Enquiry extends React.Component {

    constructor(props)
    {
      super(props);
    }
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
           
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Legal Services</h3>
                    </Col>
                   
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                   
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                             Select Property name
                            </label>
                            <Col lg="12" sm="12">
                            <select class="browser-default custom-select" style = {{ maxWidth: "500px"}} > 
                            <option selected></option> 
                            <option value="1"></option> 
                            <option value="2"></option> 
                            
                            </select>
                           </Col>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                             Enter Legal Service
                            </label>
                            <Col lg="12" sm="12">

              <select class="browser-default custom-select" style = {{ maxWidth: "500px"}} > 
              <option selected>Power of attorney</option> 
              <option value="1">Rent Agreement</option> 
              <option value="2">Property Bills</option> 
             
              </select>
              </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                            Enter Message
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                            Date
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Jesse"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                          <Button className="btn-icon btn-3" color="primary" type="button">Submit</Button>
                        </Col>
                      </Row>
                    </div>
                 
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Enquiry;