import React from "react";
import classnames from "classnames";
// reactstrap components
import {Button,Card,CardHeader,Form,CardBody,FormGroup,InputGroup, UncontrolledTooltip,Input ,NavItem,NavLink,Nav,Progress,Table,Container,Row,Col} from "reactstrap";

import UserHeader from "user/components/Headers/Header";
import axios from 'axios';
const jwt = require("jsonwebtoken");
const config = require('../../../config.json');


class Editproperty extends React.Component {

    constructor(props)
    {
        super(props);

        const config = require('../../../config.json');
        this.api_url = config.api_url;

        let userdata1 = jwt.verify(localStorage.getItem("accessToken"), config.jwtSecret);
        this.state = {
            errors:{},
            propertydata :{},
            alert_class:'',
            isLoading: false,
            fa_icon:"fa fa-exclamation-circle",
            user_id : userdata1.id,
            propertyData:{}
          }
    }
    
    
    componentDidMount()
    {
      axios.get(config.api_url+'/property/'+'5eac6a670ffd0c13bc924368',{headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`} })
      .then(res => 
      {
          console.log(res.data.result)
          this.setState({
                    propertydata:res.data.result,
                    user_id : res.data.result.result,
                    propertyName : res.data.result.propertyName,
                    propertyDescription : res.data.result.propertyDescription,
                    category : res.data.result.category,
                    address : res.data.result.address,
                    city : res.data.result.city,
                    country : res.data.result.country,
                    pincode : res.data.result.pincode,
                    bedroom : res.data.result.bedroom,
                    bathroom : res.data.result.bathroom,
                    rooms : res.data.result.rooms,
                    toilet : res.data.result.toilet,
                    Garages : res.data.result.Garages,
                    spaces : res.data.result.spaces,
                    Year : res.data.result.Year,
                    larea : res.data.result.larea,
                    lunit : res.data.result.lunit,
                    unit : res.data.result.unit,
                    price : res.data.result.price,
                    propertyStatus : res.data.result.propertyStatus,
                   // errors:res.data.errors,
                   //  alert_class: res.data.alert_class,
                    errors : ""
              })
        });
  }
  
  showMessage = (alert_msg,alert_class,fa_icon) => {
    var fa_icon_display = '';
    if(fa_icon !== undefined)
    {
        fa_icon_display = <i className={fa_icon} style={{marginRight: '5px'}} ></i>        
    }
    if(alert_msg === undefined) {
        return false;
    }else{
      return(
          <div className={"alert alert-" + alert_class}>
              {fa_icon_display} 
              {alert_msg}
          </div>
      )
    }
}
    

  render() {
    return (
    <>
         <UserHeader />
         <Container className="mt--7" fluid>
            <Row>  
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="8">
                                <h3 className="mb-0">Edit Property</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                 <h6 className="heading-small text-muted mb-4"> Property Details </h6>
                                 <div className="pl-lg-4">  
                                 <Row>
                                    <Col lg="12">
                                    <FormGroup>
                                            <label  className="form-control-label" htmlFor="input-username">
                                            Property Name
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            name="propertyName"
                                            placeholder="Property Name"
                                            type="text"
                                            Value={this.state.propertydata.propertyName}
                                            />
                                      </FormGroup>
                                    </Col>

                                    <Col lg="12">
                                        <FormGroup>
                                            <label  className="form-control-label"  htmlFor="input-email">
                                            Property Description
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            placeholder="A few words about you ..."
                                            rows="4"
                                            type="textarea"
                                            name="propertyDescription"
                                            Value={this.state.propertydata.propertyDescription}
                                            />
                                        </FormGroup>
                                    </Col>
                                 </Row>    
                                 <Row>
                                    <Col lg="6">
                                         <FormGroup>
                                            <label  className="form-control-label"  htmlFor="input-first-name">
                                            Property Status
                                            </label>
                                            <select   Value={this.state.propertydata.propertyStatus}  name="propertyStatus" 
                                            class="browser-default custom-select" style = {{ maxWidth: "500px"}} > 
                                            <option selected>Apartment</option> 
                                            <option value="1">House</option> 
                                            </select>
                                         </FormGroup>
                                     </Col>
                                     <Col lg="6">
                                        <FormGroup>
                                            <label   className="form-control-label" htmlFor="input-last-name">
                                            Home Category
                                            </label>
                                            <select  name="category" Value={this.state.propertydata.category}  
                                            class="browser-default custom-select" style = {{ maxWidth: "500px"}} > 
                                                <option selected>1 RK</option> 
                                                <option value="1">1 BHK</option> 
                                                <option value="2">2 BHK</option>  
                                                <option value="3">3 BHK</option>  
                                                <option value="4">4 BHK</option>  
                                                </select>
                                        </FormGroup>
                                     </Col>
                                 </Row>
                                 </div>
                                 <hr className="my-4" />
                                 <h6 className="heading-small text-muted mb-4"> Upload  </h6>
                                 <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="input-address"
                                            >
                                            Upload your images
                                            </label>
                                            <input type="file" id="files" name="files[]" multiple=""/>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                 </div>
                                 <hr className="my-4" />    
                                 <h6 className="heading-small text-muted mb-4"> Property Address    </h6>
                                 <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-address">
                                                Address
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                placeholder="Home Address"
                                                type="text"
                                                name="address"
                                                Value={this.state.propertydata.address}  
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label"   htmlFor="input-city">
                                                City
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                placeholder="City"
                                                type="text"
                                                name="city"
                                                Value={this.state.propertydata.city}  
                                                />
                                           </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label  className="form-control-label"  htmlFor="input-country">
                                                     Country
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                placeholder="Country"
                                                type="text"
                                                name="country"
                                                Value={this.state.propertydata.country}  
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label  className="form-control-label"  htmlFor="input-country">
                                                Postal code
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                placeholder="Postal code"
                                                type="number"
                                                name="pincode"
                                                Value={this.state.propertydata.pincode}  
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">House Features</h6>

                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-city">
                                                    Bedroom
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Bedroom"
                                                    type="text"
                                                    name="bedroom"
                                                    Value={this.state.propertydata.bedroom}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-country">
                                                    Bathroom
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Bathroom"
                                                    type="text"
                                                    name="bathroom"
                                                    Value={this.state.propertydata.bathroom}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label" htmlFor="input-country">
                                                    Rooms
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Rooms"
                                                    type="number"
                                                    name="rooms"
                                                    Value={this.state.propertydata.rooms}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-city">
                                                    Toilet
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Toilet"
                                                    type="text"
                                                    name="toilet"
                                                    Value={this.state.propertydata.toilet}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-country">
                                                    Garages
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Garages"
                                                    type="text"
                                                    name="Garages"
                                                    Value={this.state.propertydata.Garages}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-country">
                                                    Open Space
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder=" Open Space"
                                                    type="number"
                                                    name="spaces"
                                                    Value={this.state.propertydata.spaces}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-city">
                                                    Year Built
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder=" Year Built"
                                                    type="text"
                                                    name="Year"
                                                    Value={this.state.propertydata.Year}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label className="form-control-label"   htmlFor="input-country">
                                                    Land Area
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Land Area"
                                                    type="text"
                                                    name="larea"
                                                    Value={this.state.propertydata.larea}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label  className="form-control-label" htmlFor="input-country">
                                                    Land Unit
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder=" Land Unit"
                                                    type="number"
                                                    name="lunit"
                                                    Value={this.state.propertydata.lunit}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label  className="form-control-label"  htmlFor="input-city">
                                                    Unit
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Unit"
                                                    type="text"
                                                    name="unit"
                                                    Value={this.state.propertydata.unit}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-country">
                                                    Price
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    placeholder="Price"
                                                    type="text"
                                                    name="price"
                                                    Value={this.state.propertydata.price}  
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button onClick={this.submitPostPropertyForm} className="btn-icon btn-3" color="primary" type="button">Update</Button>
                                </div>
                            </Form>
                        </CardBody>    
                    </Card>   
                </Col> 
            </Row> 
         </Container>    
    </>       
    );
  }    

}

export default Editproperty;    