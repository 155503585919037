import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

 
import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import Register from "views/examples/Register.js";
import Aboutus from "views/examples/Aboutus.js";
import Contact from "views/examples/Contact.js";
import Management from "views/examples/Management.js";
import Owner from "views/examples/Owner.js";
import Tenant from "views/examples/Tenant";
import Listing from "views/examples/Listing.js";
import PropertyDetails from "views/examples/PropertyDetails.js";
import VerifyEmail from "views/examples/verify_register_email";

import UserLayout from "user/layouts/Admin"
//import UserLayout from "user/layouts/Useradmin";
import UserAuthLayout from "user/layouts/Auth";

 
import "user/assets/vendor/nucleo/css/nucleo.css";
import "user/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "user/assets/scss/argon-dashboard-react.scss";  

ReactDOM.render(
  
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Index {...props} />} />
      <Route path="/landing-page" render={props => <Landing {...props} />}/>
      <Route path="/login-page" exact render={props => <Login {...props} />} />
  
      <Route path="/register-page"  render={props => <Register {...props} />} />

      <Route path="/Management" render={props => <Management {...props} />} />
      <Route path="/about-page"  render={props => <Aboutus {...props} />} />
      <Route path="/Contact" render={props => <Contact {...props} />} />
      <Route path="/Owner" render={props => <Owner {...props} />} />
      <Route path="/Tenant" render={props => <Tenant {...props} />} />
      <Route path="/Listing" render={props => <Listing {...props} />} />
      <Route path="/PropertyDetails" render={props => <PropertyDetails {...props} />} />

      <Route path="/verifyuser/:token" render={props => <VerifyEmail {...props} />} />


      {/* user links */}
      <Route path={"/user"} render={props => <UserLayout {...props} />} />
      <Route path="/userauth" render={props => <UserAuthLayout {...props} />} />

      <Redirect to="/" />

    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
); 
