import React from "react";
import { Container, Row, Col } from "reactstrap";
// core components
import Navbars from "components/Navbars/Navbars.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import axios from 'axios';
const config = require('../../config.json');

class VerifyEmail extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            error_type:'',
            errors:{},
            alert_class:'',
            fa_icon:'',
            isLoading:false,
            error_type:''
          }
    }

    componentDidMount() {
        const { token } = this.props.match.params
        console.log(token);
        axios.get(config.api_url+'/users/verify_email/'+token)
        .then(res => {
            if(res.data.success)
            {
                 
            }

            this.setState({
                errors      :   res.data.errors,
                alert_class :   res.data.alert_class,
                fa_icon     :   res.data.fa_icon,
                error_type  :   res.data.error_type
            });
            
           
        }); 
      }

      showMessage = (alert_msg,alert_class,fa_icon) => {
        var fa_icon_display = '';
        if(fa_icon !== undefined)
        {
            fa_icon_display = <i className={fa_icon} style={{marginRight: '5px'}} ></i>        
        }
        if(alert_msg === undefined) {
            return false;
        }else{
          return(

              <div className={"alert alert-" + alert_class}>
                  {fa_icon_display} 
                  {alert_msg}
              </div>
          )
        }
    }


  render() {


    console.log(this.state.error_type);

    let display_error = "";
    if(this.state.error_type === "1")
    {
        display_error = (
            <div>
                <h2 style={{color:"#155724"}} ><i className="fa fa-check-circle"></i> Thank You </h2>
                <h5 style={{color: "#155724",background: "#d4edda",padding: "5px",borderRadius: "5px",border: "1px solid #c3e6cb"}}>
                    Your email has been verified. Please login to continue
                </h5>
            </div>     
        );
    }
    else if(this.state.error_type === "2" )
    {
        display_error = (
           <div>
                <h2 style={{color:"#721c24"}}>
                    <i className="fa fa-exclamation-circle"></i> Oops 
                </h2>
                <h5 style={{color: "#721c24",background: "#f8d7da",padding: "5px",borderRadius: "5px",border: "1px solid #f5c6cb"}}>
                    Your browser made invalid request, Verified token might expired. Try again.
                </h5>
            </div>     
        );
    }else if(this.state.error_type === "")
    {
        display_error = (
            <div>
                <h2 style={{color:"#31708f"}}>
                    <i className="fa fa-exclamation-circle"></i> Please  Wait ...
                </h2>
            <h5 style={{color: "#31708f",background: "#d9edf7",padding: "5px",borderRadius: "5px",border: "1px solid #bce8f1"}}>
                Processing Your Request.
            </h5>
        </div>     
         );
          
    }
    else 
    {
        display_error = (
           <div>
                <h2 style={{color:"#721c24"}}>
                    <i className="fa fa-exclamation-circle"></i> Oops 
                </h2>
                <h5 style={{color: "#721c24",background: "#f8d7da",padding: "5px",borderRadius: "5px",border: "1px solid #f5c6cb"}}>
                    Something went wrong, Your browser made invalid request. Try again.
                </h5>
            </div>     
        );
    }
    return (
      <>
        <Navbars />
        <section className="section">
          <Container>
            <h1 className="display-1 mb-0" style={{textAlign:"center"}}>Email Verification</h1>
            <Row className="row-grid">
              <Col md="12">
                    <center>{display_error}</center>
              </Col>
            </Row>

          </Container>
        </section>
        <CardsFooter />

      </>
    );
  }
}

export default VerifyEmail;