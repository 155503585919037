
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, Card, CardImg} from "reactstrap";

class BasicElements extends React.Component {
  render() {
    return (
      <>
        <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/ill/banner.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                      It's a concept introduced for Property owners
                      </h4>
                      <p className="lead text-italic text-white">
                      Want to rent your Property and earn from your property with our systematic and verified approach?? We provide you with fast
                       tenant placement with verified background check so that your property is in safe hands
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-settings" />
                    </div>
                    <h3>It's a Problem Slover for tenants with happy to live-in homes</h3>
                    <p className="lead">
                    Tenants can begin with selecting the properties which are suitable and send us 
                    enquiry or can comment below the list of properties and can enjoy ready to use homes.
                    </p>
                    
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
      </>
    );
  }
}

export default BasicElements;
