
import React from "react";

// reactstrap components
import { Progress, Col,Container, Row, Button } from "reactstrap";

class ProgressSection extends React.Component {
  render() {
    return (
      <>
         <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/ill/property.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    
                    <h3>ABOUT US</h3>
                    <p>
                    Krislin Consultants LLP is Pune based consultant dealing in property renting business. The innovative services we 
                    offer will give immense pleasure and relief for flat owners and tenants.
                    </p>
                    <p>
                    Flat owners who are away from their property for mutual benefit can have excellent benefits.
                    </p>
                    <Button
                            className="mt-4"
                            color="default"
                            href="/about-page"
                           
                          >
                            Read more
                          </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
      </>
    );
  }
}

export default ProgressSection;
