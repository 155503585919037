import React from "react";
// reactstrap components
import { UncontrolledCarousel, Row, Col, CardBody, Card, CardHeader, Form, FormGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label, Container, Button } from "reactstrap";
import Slider from "nouislider";

const item1 = [
    {
        src: require("assets/img/ill/prop/tata1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/tata2.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];
const item2 = [
    {
        src: require("assets/img/ill/prop/ten1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/ten2.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/ten3.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];
const item3 = [
    {
        src: require("assets/img/ill/prop/lt1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/lt2.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/lt3.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];
const item4 = [
    {
        src: require("assets/img/ill/prop/br1.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/br2.jpg"),
        altText: "",
        caption: "",
        header: ""
    },
    {
        src: require("assets/img/ill/prop/br3.jpg"),
        altText: "",
        caption: "",
        header: ""
    }
];

class List extends React.Component {
    state = {
        simpleValue: 100.0,
        rangeLow: 200.0,
        rangeHigh: 400.0,
        rangeLowPrice: 0.0,
        rangeHighPrice: 300.0
    };
    componentDidMount() {
        var slider1 = this.refs.slider1;
        Slider.create(slider1, {
            start: [200.0, 400.0],
            connect: [false, true, false],
            step: 0.01,
            range: { min: 100.0, max: 500.0 }
        }).on(
            "update",
            function (values, handle) {
                this.setState({ rangeLow: values[0], rangeHigh: values[1] });
            }.bind(this)
        );
        /////////////
        var slider2 = this.refs.slider2;
        Slider.create(slider2, {
            start: [0.0, 300.0],
            connect: [false, true, false],
            step: 0.01,
            range: { min: 0.0, max: 500.0 }
        }).on(
            "update",
            function (values, handle) {
                this.setState({ rangeLowPrice: values[0], rangeHighPrice: values[1] });
            }.bind(this)
        );
    }
    render() {
        return (
            <>
                <Container fluid>
                    <Row className="justify-content-center">
                        <div class="mb-3 mt-5">
                            <button type="button" class="ml-1 btn btn-info">Apartments</button>
                            <button type="button" class="ml-1 btn btn-info">Houses</button>
                            <button type="button" class="ml-1 btn btn-info">Garages</button>
                        </div>
                    </Row>
                    <div className="rounded shadow-lg overflow-hidden ">
                        {/* <Col lg="12"> */}

                        <Row>
                            <Col lg="3" className="">
                                <Container>
                                    <div class="mb-3 mt-5">
                                        <div className="row-grid justify-content-center row">
                                            <h4 className="display-4 mb-0">Search Property</h4>
                                        </div><br />
                                        <Form>
                                            {/* <div className="row-grid justify-content-center row">
                                                <button className="btn btn-info btn mb-sm-3">Rent</button>
                                                <button className="btn btn-info btn mb-sm-3">Sale</button>
                                            </div> */}
                                            <FormGroup>
                                                <UncontrolledDropdown style={{ width: "280px" }} >
                                                    <DropdownToggle caret color="secondary" block>
                                                        Location
                                            </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            Pune
                                                </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            Mumbai
                                                </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            USA
                                                </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </FormGroup>
                                            <FormGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle style={{ width: "280px" }} caret color="secondary">
                                                        Type
                                            </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            1BHK
                                                </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            2BHK
                                                </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            3BHK
                                                </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                                                            Duplex
                                                </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                            </FormGroup>
                                            <Label>Size : [0 sqft - 1200 sqft]</Label>
                                            <div className="slider" ref="slider1" />
                                            <Row className="d-none">
                                                <Col xs="6">
                                                    <span className="range-slider-value value-low">
                                                        {this.state.rangeLow}
                                                    </span>
                                                </Col>
                                                <Col className="text-right" xs="6">
                                                    <span className="range-slider-value value-high">
                                                        {this.state.rangeHigh}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Label>Price : [0 - 100000]</Label>
                                            <div className="slider" ref="slider2" />
                                            <Row className="d-none">
                                                <Col xs="6">
                                                    <span className="range-slider-value value-low">
                                                        {this.state.rangeLowPrice}
                                                    </span>
                                                </Col>
                                                <Col className="text-right" xs="6">
                                                    <span className="range-slider-value value-high">
                                                        {this.state.rangeHighPrice}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <div className="row-grid justify-content-center row" style={{ marginLeft: "0px" }}>
                                                <Button block color="info" size="lg">Search Property</Button></div>
                                        </Form>
                                    </div>
                                </Container>
                            </Col>
                            <Col lg="9">
                                <Row>
                                    <Col lg="6" className="dd">
                                        <Card>
                                            {/* <CardHeader>Tata la Montana 10000/-</CardHeader> */}
                                            <UncontrolledCarousel items={item1} />
                                            <CardBody>
                                                <h5>Tata la Montana <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>10000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Pune, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 620 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 1BHK</span>
                                                    </h6>
                                                </div>
                                                <br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className="dd">
                                        <Card>
                                            <UncontrolledCarousel items={item2} />
                                            <CardBody>
                                                <h5>Tennessee <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>15000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Pune, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 2000 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 3BHK</span>
                                                    </h6>
                                                </div><br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" className="dd">
                                        <Card>
                                            <UncontrolledCarousel items={item3} />
                                            <CardBody>
                                                <h5>L&T Seawoods Limited <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>62000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Mumbai, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 1053 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 1BHK</span>
                                                    </h6>
                                                </div>
                                                <br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className="dd">
                                        <Card>
                                            <UncontrolledCarousel items={item4} />
                                            <CardBody>
                                                <h5>Blue Ridge <span style={{ color: "red", float: "right" }}><i class="fa fa-inr"></i>25000/- </span> </h5>
                                                <div>
                                                    <h6><i class="ni ni-pin-3"></i> Location <span style={{ float: "right", fontWeight: "500" }}> Pune, India</span>
                                                    </h6>
                                                </div>
                                                <div><h6><i class="ni ni-tag"></i>  Property size<span style={{ float: "right", fontWeight: "500" }}> 550 sqft</span></h6></div>
                                                <div>
                                                    <h6><i class="ni ni-building"></i>  Property Type<span style={{ float: "right", fontWeight: "500" }}> 1BHK</span>
                                                    </h6>
                                                </div>
                                                <br />
                                                <div>
                                                    <button type="button" class="btn btn-danger btn"><span class="btn-inner--icon mr-2"><i class="ni ni-send"></i></span>Enquire</button>
                                                    <button type="button" class="btn-1 ml-1 btn btn-outline-danger"><span class="btn-inner--icon mr-2"><i class="ni ni-check-bold"></i></span>Interested</button>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* </Col> */}
                    </div>
                </Container>
            </>
        );
    }
}

export default List;
