import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
//import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Owner extends React.Component {
  render() {
    return (
      <>
       <div className="bg-default-section">
        <DemoNavbar />
        </div>
        <section className="section">
          <Container>
            <h1 className="display-1 mb-0">PROPERTY OWNER</h1>
            <Row>
              <Col md="12">
                <h1 className="mb-0">The way we Carry :Your Property is Our Priority!</h1>
                <p>Rental income should be easy and not a full-time job, A property owner is a person who is the owner of the property and is willing to provide property on rent. Krislin Consultants LLP provides service for property owner by posting their property at our website with the fast tenant with their background check ensuring that your property is in safe hands. The property owner can increase the rent by a fixed percentage each year, according to individual state and/or municipal law. They can also decrease the rent if they deem necessary. With our team of lawyers, we help you to get ready with the legal formalities of the properties.We also upload high-quality resolution photos on our website for a better and a clearer view of any property, hence with the high-resolution photos of property, tenants can view the exact look of the property and can rent the property having a clearer view of property Enjoying a smooth, effortless, and much more pleasurable experience when finding ideal home.We are also responsible for carrying out Maintenance and Repair of the properties.</p>
              </Col>
            </Row>
            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <h3>1 . Rent It and get a fruit-full income and happiness!</h3>
                <p>
                  As an owner you can list your property in a few minutes by filling out our simple form and Your property will go live after verification.
                  Assign your property to us through signing a agreement with us and handover your keys and we provide you with the best rent price in the market.Once your Property is under us, we are here to provide you with end to end coordination. We manage deposits too, we create a dedicated owners-tenant deposit account, so that owner no need to involve in this and save effort, time and money.Keep your fruit-full rent and leave the rest hassle to us.
                        </p>
              </Col>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/rent.png")}
                  style={{ height: "250px", width: "250px" }}
                />
              </Col>

            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>

            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/tenant-search.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h3>2. Advertisement and Tenant Search</h3>
                <p>
                  We advertise your property with high definition picture quality to get a clear picture of your property.
                  We quickly screen, select, and place great tenants with comprehensive background checks in your property using our systematic process to ensure that your property is in safe hands.
                  Property owner is also provided with the police verification of tenants and other legal services
                      </p>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>

            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <h3>3. Receive continuous rent and constant updates</h3>
                <p>
                  Flat owners can stay anywhere in the world comfortably without any worry about his/her property and they can view their property online periodically and receive rent and get updates about the property. With regular updates of your property , we also assure you with timely rent.
                  Owners will get photos of their property every 6 months and the amenities available in the property to assure that the property is in safe hands. Tenants will also update the electricity bill so that owner is updated with the bills.
                      </p>
              </Col>

              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/continious_update.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <img
                  alt="..."
                  className="img-fluid img-center"
                  src={require("assets/img/theme/dashed-line.png")}
                />
              </Col>
            </Row>


            <Row className="row-grid" style={{ backgroundColor: "#ECEAEA" }}>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/hassle-free-operation.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h3>4. What makes hassle free operation?</h3>
                <p>
                  Post Move-in Service
                  We share a move-in report with the tenant to capture the condition of the apartment. This is useful at the time of vacating the apartment. We create a move-in checklist capturing all details of the property at the time of handover. This is used as reference at the time of move-out to avoid discrepancies.
                      </p>
              </Col>
            </Row>

          </Container>
        </section>
        <CardsFooter />
      </>
    );
  }
}

export default Owner;