import React from 'react';
import axios from 'axios';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import SimpleFooter from 'components/Footers/SimpleFooter.js';

const config = require('../../config.json');

class Register extends React.Component {
	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
	}

	constructor() {
		super();
		this.state = {
			fields: {},
			errors: {},
		};

		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
	}

	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	submituserRegistrationForm(e) {
		e.preventDefault();
		if (this.validateForm()) {
			let fields = this.state.fields;
			fields.mobileno = fields.mobileno;

			this.setState({
				errors: {
					login_msg: 'Please wait...',
					login_cls: 'info',
					login_icon: 'fa fa-exclamation-circle',
				},
			});
			try {
				console.log(fields);
				axios.post(config.api_url + '/users', fields).then((res) => {
					// this.setState({
					// 	errors: res.data.error,
					// });
					console.log(res.data.error);

					// if (res.data.status) {
					// 	this.setState({
					// 		fields: {},
					// 	});
					// 	window.location.href = config.site_url;
					// } else {
					// 	alert('Something went wrong');
					// }
				});
			} catch (e) {
				console.log(e);
			}
		}
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields['fname']) {
			formIsValid = false;
			errors['fname'] = '*Please enter your first name.';
		}

		if (typeof fields['fname'] !== 'undefined') {
			if (!fields['fname'].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors['fname'] = '*Please enter alphabet characters only.';
			}
		}

		if (!fields['lname']) {
			formIsValid = false;
			errors['lname'] = '*Please enter your last name.';
		}

		if (typeof fields['lname'] !== 'undefined') {
			if (!fields['lname'].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors['lname'] = '*Please enter alphabet characters only.';
			}
		}

		if (!fields['emailid']) {
			formIsValid = false;
			errors['emailid'] = '*Please enter your email-ID.';
		}

		if (typeof fields['emailid'] !== 'undefined') {
			//regular expression for email validation
			var pattern = new RegExp(
				/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
			);
			if (!pattern.test(fields['emailid'])) {
				formIsValid = false;
				errors['emailid'] = '*Please enter valid email-ID.';
			}
		}

		if (!fields['mobileno']) {
			formIsValid = false;
			errors['mobileno'] = '*Please enter your mobile no.';
		}

		if (typeof fields['mobileno'] !== 'undefined') {
			if (!fields['mobileno'].match(/^[0-9]{10}$/)) {
				formIsValid = false;
				errors['mobileno'] = '*Please enter valid mobile no.';
			}
		}

		if (!fields['address']) {
			formIsValid = false;
			errors['address'] = '*Please enter your address.';
		}

		if (!fields['zipcode']) {
			formIsValid = false;
			errors['zipcode'] = '*Please enter your pincode / zip code.';
		}

		if (!fields['city']) {
			formIsValid = false;
			errors['city'] = '*Please enter your city.';
		}

		if (!fields['state']) {
			formIsValid = false;
			errors['state'] = '*Please enter your state.';
		}

		if (!fields['country']) {
			formIsValid = false;
			errors['country'] = '*Please enter your country.';
		}

		if (!fields['password']) {
			formIsValid = false;
			errors['password'] = '*Please enter your password.';
		}

		if (!fields['cpassword']) {
			formIsValid = false;
			errors['cpassword'] = '*Please enter confrim password.';
		}

		if (typeof fields['password'] !== 'undefined') {
			if (
				!fields['password'].match(
					/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
				)
			) {
				formIsValid = false;
				errors['password'] = '*Please enter secure and strong password.';
			}
		}

		if (fields['password'] != fields['cpassword']) {
			formIsValid = false;
			errors['password'] = "*Passwords don't match";
		}

		this.setState({
			errors: errors,
		});
		return formIsValid;
	}

	showMessage = (alert_msg, alert_class, fa_icon) => {
		var fa_icon_display = '';
		if (fa_icon !== undefined) {
			fa_icon_display = <i className={fa_icon} style={{ marginRight: '5px' }}></i>;
		}
		if (alert_msg === undefined) {
			return false;
		} else {
			return (
				<div className={'alert alert-' + alert_class}>
					{fa_icon_display}
					{alert_msg}
				</div>
			);
		}
	};
	render() {
		return (
			<>
				<DemoNavbar />
				<main ref='main'>
					<section className='section section-shaped section-lg'>
						<div className='shape shape-style-1 bg-gradient-default'>
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
						<Container className='pt-lg-7'>
							<Row className='justify-content-center'>
								<Col lg='10'>
									<Card className='bg-secondary shadow border-0'>
										<CardHeader className='head-register'>
											<div className='text-center'>
												<img
													alt='...'
													className='logo-register'
													src={require('assets/img/brand/krislin-logo.png')}
												/>
												<Button
													className=''
													color='default'
													href='#pablo'
													onClick={(e) => e.preventDefault()}
												>
													<span className='btn-inner--text'>
														Sign up with
													</span>
												</Button>
											</div>
										</CardHeader>
										<CardBody className='px-lg-5 py-lg-5'>
											{this.showMessage(
												this.state.errors.error_msg,
												this.state.errors.error_cls,
												this.state.errors.error_icon
											)}

											<Form role='form'>
												<Container className=''>
													<Row className=''>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='fa fa-user' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='fname'
																		placeholder='First Name'
																		type='text'
																		value={
																			this.state.fields.fname
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.fname}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='fa fa-user' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='lname'
																		placeholder='Last Name'
																		type='text'
																		value={
																			this.state.fields.lname
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.lname}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='ni ni-email-83' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='emailid'
																		placeholder='Email'
																		type='emailid'
																		value={
																			this.state.fields
																				.emailid
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.emailid}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='fa fa-mobile' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<select
																		class='custom-select'
																		name='country_code'
																		onChange={this.handleChange}
																	>
																		<option>Select</option>
																		<option value='+91'>
																			+91
																		</option>
																		<option value='+1'>
																			+1
																		</option>
																	</select>
																	<Input
																		name='mobileno'
																		placeholder='Mobile'
																		type='mobile'
																		value={
																			this.state.fields
																				.mobileno
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.mobileno}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='fa fa-address-card' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='address'
																		placeholder='Address'
																		type='address'
																		value={
																			this.state.fields
																				.address
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.address}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='fa fa-map-pin' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='zipcode'
																		placeholder='pincode'
																		type='zipcode'
																		value={
																			this.state.fields
																				.zipcode
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.zipcode}
																</p>
															</FormGroup>
														</Col>
														<Col lg='4'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='fa fa-building' />
																		</InputGroupText>
																	</InputGroupAddon>

																	<Input
																		name='city'
																		placeholder='City'
																		type='city'
																		value={
																			this.state.fields.city
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.city}
																</p>
															</FormGroup>
														</Col>
														<Col lg='4'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='ni ni-square-pin' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='state'
																		placeholder='State'
																		type='state'
																		value={
																			this.state.fields.state
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.state}
																</p>
															</FormGroup>
														</Col>
														<Col lg='4'>
															<FormGroup>
																<InputGroup className='input-group-alternative mb-3'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='ni ni-planet' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		name='country'
																		placeholder='Country'
																		type='country'
																		value={
																			this.state.fields
																				.country
																		}
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.country}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='ni ni-lock-circle-open' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		type='password'
																		placeholder='Password'
																		name='password'
																		onChange={this.handleChange}
																		autoComplete='off'
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.password}
																</p>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<InputGroup className='input-group-alternative'>
																	<InputGroupAddon addonType='prepend'>
																		<InputGroupText>
																			<i className='ni ni-lock-circle-open' />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input
																		placeholder='Confirm Password'
																		name='cpassword'
																		type='password'
																		autoComplete='off'
																		onChange={this.handleChange}
																	/>
																</InputGroup>
																<p
																	style={{
																		color: 'red',
																		fontSize: '12px',
																	}}
																>
																	{this.state.errors.cpassword}
																</p>
															</FormGroup>
														</Col>
														<Col lg='5'>
															<div className='custom-control custom-control-alternative custom-checkbox'>
																<input
																	className='custom-control-input'
																	id='customCheckRegister'
																	type='checkbox'
																/>
																<label
																	className='custom-control-label'
																	htmlFor='customCheckRegister'
																>
																	<span>
																		I agree with the{' '}
																		<a
																			href='#pablo'
																			onClick={(e) =>
																				e.preventDefault()
																			}
																		>
																			Privacy Policy
																		</a>
																	</span>
																</label>
															</div>
														</Col>

														<div className='text-center'>
															<Button
																onClick={
																	this.submituserRegistrationForm
																}
																className='mt-4'
																color='default'
																type='button'
															>
																Create account
															</Button>
														</div>
													</Row>
												</Container>
											</Form>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</section>
				</main>
				<SimpleFooter />
			</>
		);
	}
}

export default Register;
