import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
//import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Management extends React.Component {
  render() {
    return (
      <>
        <div className="bg-default-section">
        <DemoNavbar />
        </div>
        <section className="section">
          <Container>
            <h1 className="display-1 mb-0">PROPERTY MANAGEMENT</h1>
            <Row>
              <Col md="12">
                <p>We understand that your real estate property is a large investment, and it is a matter of stress to keep it idle or rent without reliable care. Keeping your property idle does not necessarily mean it will remain safe. Idle properties are more prone to unauthorized/illegal use so join hands with us and we assure you with the systematic hassle free property management</p>
              </Col>
            </Row>
            <Row className="row-grid">
              <Col md="6">
                <h6>Keeping Property in Mint Condition is an investment!</h6>
                <p>
                  Your property continues to remain a premium asset and not turn into an expensive maintenance liability. Property management services will help your property attract the best rent.

                  We understand that your time is limited in hustle-bustle life hence everything is taken care with 0 or 1 visit.

                  Krislin assures you to provide the end to end property management service through the following specified roadmap :
                        </p>
              </Col>
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/property_investment.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>
            </Row>

            <Row className="row-grid">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/documents.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h6>1. Prior Maintenance and Documentation</h6>
                <p>
                  The first impression is the last impression! The property is checked overall with its looks, amenities, electric and plumbing problems. Once the property is fixed, we are ready to market and find you with the verified tenant. We also provide you with paperwork such as agreement drafting, police verification, society form, legal documentation in cooperation with our in-house expert lawyers.
                      </p>
              </Col>
            </Row>

            <Row className="row-grid">
              <Col md="6">
                <h6>2. Marketing of your property and tenant search</h6>
                <p>
                  Once the property is verified with the maintenance, we are ready to showcase your property with high definition quality images of your property and search you with the suitable tenant with their background check to ensure that your property is in safe hands.
                      </p>
              </Col>

              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/search.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>
            </Row>


            <Row className="row-grid">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/maintainance_1.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h6>3. 24/7 Maintenance and Repair</h6>
                <p>
                  We provide you with regular checkup of your property with any kind of damage and immediately repair the damage. Tie-ups with various vendors ensure high quality and best possible priced repairs with providing 24/7 maintenance and repair service. We believe in transparency and hence we provide you with each and every bill of repair that took place on your property as a proof. We also handle all the disputes from tenant end and society.
                      </p>
              </Col>
            </Row>

            <Row className="row-grid">
              <Col md="6">
                <h6>4. Periodic Check and Regular Updates</h6>
                <p>
                  Whether you live a few blocks from your property or hundred miles away from your property, we make effort to visit your site at regular intervals of time and keep you informed about your property.
                      </p>
              </Col>

              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/update.png")}
                  style={{ height: "200px", width: "300px" }}
                />
              </Col>
            </Row>

            <Row className="row-grid">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/collection.png")}
                  style={{ height: "250px", width: "300px" }}
                />
              </Col>

              <Col md="6">
                <h6>5. Rent Collection</h6>
                <p>
                  Provide easy to read monthly cash-flow statements which offer a detailed breakdown of income and itemized expenses assuring you with timely rent.
                      </p>
              </Col>
            </Row>


          </Container>
        </section>
        <CardsFooter />
      </>
    );
  }
}

export default Management;